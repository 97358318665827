import { mapState } from 'vuex'
import { httpClient } from '~/utils/axios'
import _ from 'lodash';
import dayjs from 'dayjs';
import Compressor from 'compressorjs'

export default {
  computed: {
    ...mapState({
      userAgent: state => state.userAgent,
      isPC: state => state.devise.isPC,
      isLogin: state => !!state.auth.authToken,
      new_alert_size: state => state.auth.new_alert_size,
      company: state => state.auth.company,
      client: state => state.auth.client,
      inprogressTaskSets: state => state.auth.inprogressTaskSets,
    }),
  },
  methods: {
    limitDateOver(limit_date){
      if(!limit_date) return false
      let today = dayjs()
      return today.isAfter(dayjs(limit_date))
    },
    autoLink(text) {
      return _.isString(text) ? text.replace(/(https?:\/\/[^\s]*)/g, "<a href='$1' target='_blank'>$1</a>") : '';
    },
    // document
    filterDocs(docs, doc_ids){
      return _.filter(docs, (doc)=>{return _.includes(doc_ids, doc.id)})
    },
    async getDocuments(document_ids){
      let self = this
      let documents = []
      documents = await httpClient
        .get('/cli/floor/docs.json', {params: {doc_ids: doc_ids}})
        .then(async (res) => {
          if (res.data.status === 'success') return res.data.data.docs
        })
      return documents
    },
  }
}
