import { httpClient } from '~/utils/axios'
import _ from 'lodash';
import dayjs from 'dayjs';

const getDefaultState = () => {
  return {
    // contact
    firstContact: false,

    // auth
    authToken: null,
    company: null,
    client: null,
    inprogressTaskSets: [],
    new_alert_size: 0,

    // push
    lastUpdatedAtClient: null,
    oneSignalDeviseToken: null,
  }
}

export const state = () => (getDefaultState())
export const mutations = {
  // contact
  firstContactDone(state, payload) {
    state.firstContact = true
  },
  
  // auth
  setAuthToken(state, auth_token) {
    state.authToken = auth_token
  },
  setCompany(state, company) {
    state.company = company
  },
  setClient(state, client) {
    state.client = client
  },
  setInprogressTaskSets(state, inprogressTaskSets){
    state.inprogressTaskSets = inprogressTaskSets
  },
  setLastUpdatedAt(state, payload){
    state.lastUpdatedAtClient = payload
  },
  setNewAlertSize(state, new_alert_size) {
    state.new_alert_size = new_alert_size
  },
  setOneSignalDeviseToken(state, oneSignalDeviseToken) {
    state.oneSignalDeviseToken = oneSignalDeviseToken
  },
  logout(state) {
    $nuxt.$router.push(`/login/`)
    Object.assign(state, getDefaultState())
    // window.storeCtl.commit("company/reset")
  },

}
export const getters = {
  isLogin(state) {
    return !!state.authToken
  },
}

// 戻り地はisBeforeRequest
export const actions = {
  async getUpdate(context){
    await httpClient
    .get('/cli/has_update.json')
    .then(async (res) => {
      if (res.data.status === 'success') {
        // alert size
        context.commit("setNewAlertSize", res.data.data.new_alert_size)

        // マスタの更新が新しければマスタGET
        let latest_update_at = dayjs(res.data.data.client_updated_at)
        let last_update_at = dayjs(context.state.lastUpdatedAtClient)
        if(context.state.lastUpdatedAtClient === null || latest_update_at.isAfter(last_update_at)){
          context.commit("setLastUpdatedAt", res.data.data.client_updated_at)

          // ここで一度マスターをすべて取得
          await window.storeCtl.dispatch('auth/getClient')
        }
      } else {
        window.storeCtl.commit("alert/setError", res.data.message)
      }
    })
    .finally(() => {
      window.storeCtl.commit("loading/stop")
    })
  },

  //// push
  // ログインしていて
  // LocalStrage取れていて
  // StoreとLocalstrageの値が違う
  // サーバー同期処理
  async updateOneSignalDeviseToken(context){
    const onesignal_user_id = localStorage.getItem('onesignal_user_id'); 
    if(
      onesignal_user_id &&
      window.storeCtl.state.auth.oneSignalDeviseToken !== onesignal_user_id
    ){
      await httpClient
      .post('/cli/account/update_onesignal_device_token.json', {
        onesignal_device_token: onesignal_user_id
      })
      .then((res) => {
        if (res.data.status === 'success') {
          window.storeCtl.commit("auth/setOneSignalDeviseToken", onesignal_user_id)
        } else {
          window.storeCtl.commit("alert/setError", res.data.message)
        }
      })
    }
  },

  async getClient(context){
    await httpClient
    .get('/cli/account/show.json')
    .then(async (res) => {
      if (res.data.status === 'success') {
        context.commit("setCompany", res.data.data.company)
        context.commit("setClient", res.data.data.client)
        context.commit("setInprogressTaskSets", res.data.data.inprogress_task_sets)
      } else {
        window.storeCtl.commit("alert/setError", res.data.message)
      }
    })
    .finally(() => {
      window.storeCtl.commit("loading/stop")
    })
  },

}
