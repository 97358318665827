import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _65412ea2 = () => interopDefault(import('../pages/docs/index.vue' /* webpackChunkName: "pages/docs/index" */))
const _3e3e5d4b = () => interopDefault(import('../pages/login/index.vue' /* webpackChunkName: "pages/login/index" */))
const _90566cbc = () => interopDefault(import('../pages/login/deputy.vue' /* webpackChunkName: "pages/login/deputy" */))
const _c97ad0be = () => interopDefault(import('../pages/settings/alert.vue' /* webpackChunkName: "pages/settings/alert" */))
const _5e8804bc = () => interopDefault(import('../pages/settings/alerts.vue' /* webpackChunkName: "pages/settings/alerts" */))
const _5710f22e = () => interopDefault(import('../pages/settings/profile.vue' /* webpackChunkName: "pages/settings/profile" */))
const _1415ef32 = () => interopDefault(import('../pages/task_sets/_filter.vue' /* webpackChunkName: "pages/task_sets/_filter" */))
const _4d31daf5 = () => interopDefault(import('../pages/tasks/_filter.vue' /* webpackChunkName: "pages/tasks/_filter" */))
const _3584e9b1 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/docs/",
    component: _65412ea2,
    pathToRegexpOptions: {"strict":true},
    name: "docs"
  }, {
    path: "/login/",
    component: _3e3e5d4b,
    pathToRegexpOptions: {"strict":true},
    name: "login"
  }, {
    path: "/login/deputy/",
    component: _90566cbc,
    pathToRegexpOptions: {"strict":true},
    name: "login-deputy"
  }, {
    path: "/settings/alert/",
    component: _c97ad0be,
    pathToRegexpOptions: {"strict":true},
    name: "settings-alert"
  }, {
    path: "/settings/alerts/",
    component: _5e8804bc,
    pathToRegexpOptions: {"strict":true},
    name: "settings-alerts"
  }, {
    path: "/settings/profile/",
    component: _5710f22e,
    pathToRegexpOptions: {"strict":true},
    name: "settings-profile"
  }, {
    path: "/task_sets/:filter?/",
    component: _1415ef32,
    pathToRegexpOptions: {"strict":true},
    name: "task_sets-filter"
  }, {
    path: "/tasks/:filter?/",
    component: _4d31daf5,
    pathToRegexpOptions: {"strict":true},
    name: "tasks-filter"
  }, {
    path: "/",
    component: _3584e9b1,
    pathToRegexpOptions: {"strict":true},
    name: "index"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
