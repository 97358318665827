import { httpClient } from '~/utils/axios'
import _ from 'lodash';
import dayjs from 'dayjs';
import { form_config } from '~/utils/models'

const getDefaultState = () => {
  return {
    task_sets: [],
  }
}

export const state = () => (getDefaultState())
export const mutations = {
  reset(state) {
    Object.assign(state, getDefaultState())
  },

  setTaskSets(state, task_sets) {
    state.task_sets = task_sets
  },
  
}
export const getters = {
}
export const actions = {
}
