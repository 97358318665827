export let form_config = {
  headers: {
    "content-type": "multipart/form-data",
  }
}  

export let doc = {
  sort_id: -1,
  isShow: true,
  id: null,
  task_set_id: null,
  task_id: null,
  doc_type: null,
  file_type: null,
  file_name: null,
  origin_file_name: null,
  url: null,
  img: null,
  file: null,
}
